import { message } from "antd";
import axios from "axios";
import * as config from "./../../extra/config"
import { Base64 } from "js-base64";
import { getParamUrlByKey } from "../../extra/utils";
import zIndex from "@mui/material/styles/zIndex";

const Header = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${getParamUrlByKey("token")}`,
};
const core_api = config.core_api;

async function apiForm(method, url, data) {
  var data_base64 = Base64.encode(JSON.stringify(data));
  try {
    const res = await axios({
      method: method,
      url: `${core_api}${url}`,
      data: {
        data: data_base64,
      },
      headers: Header,
    });
    return res;
  } catch (err) {
    message.error(
      {
        content: err?.response?.data?.mess,
        style: {
          width: '90%',
          margin:'25px auto'
        },
      },
      7
    );
    return err;
  }
}

const api = { apiForm };
export default api;
