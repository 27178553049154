import { PhoneFilled } from "@ant-design/icons";
import { CircularProgress, Divider } from "@mui/material";
import { Box } from "@mui/system";
import { Button, message } from "antd";
import { useEffect } from "react";
import { formatMoney, getParamUrlByKey } from "../../../extra/utils";
import ConfirmModal from "../components/ConfirmModal";
import Waiting from "../components/Waiting";
import Dowload from "./../assets/icon/download.svg";
import ImgWooribank from "./../assets/icon/imgWooribank.svg";
import Copy from "./../assets/icon/copy.svg";
import I1 from "./../assets/icon/1.svg";
import I2 from "./../assets/icon/2.svg";
import I3 from "./../assets/icon/3.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { useStyles } from "../styles";

export default function Step2({ state, dispatch, isApp }) {
  const { dataStep2, openConfirm, openWaiting, sum } = state;
  const { info, timeEnd } = dataStep2;
  const classes = useStyles();
  const dataTran = JSON.parse(localStorage.getItem("dataTran"));

  useEffect(() => {
    if (sum < 7 && sum !== 0) {
      dispatch("checkStatus");
    }
  }, [sum]);

  const css = {
    boxBtn: {
      display: "flex",
      justifyContent: "center",
      fontSize: 10,
    },
    btn: {
      padding: 5,
      background: "#ffffff",
      color: "#25806D",
      borderRadius: 4,
      display: "flex",
      alignItems: "center",
      width: 128,
      justifyContent: "center",
      cursor: "pointer",
    },
    boxQR: {
      background: "linear-gradient(88.95deg, #006838 0%, #00A69C 100%)",
      padding: 15,
      borderRadius: 15,
      marginTop: 10,
      color: "#ffffff",
      textAlign: "center",
      fontSize: 15,
    },
    value: {
      fontSize: 14,
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "5px 0px",
      color: "#3A3A3A",
    },
    divItem: {
      textAlign: "left",
      fontSize: 11,
      marginTop: 10,
      color: "#646D69",
    },
    btnCopy: {
      color: "#25806D",
      fontWeight: 400,
      fontSize: 10,
      border: "1px solid #25806D",
      borderRadius: 4,
      padding: "5px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
  };

  return (
    <div className={!isApp ? classes.body : classes.app}>
      <div
        style={{ background: "#F3F3F3" }}
        className={!isApp ? classes.view : ""}
      >
        <Box style={{ background: "#F3F3F3" }} className={classes.wrapper}>
          {/* <div className="header">Thanh toán</div>
          <Divider /> */}
          <div style={css.boxQR}>
            <div>
              <b>Quét mã QR Code</b>
            </div>
            <div style={{ margin: "5px 0px 15px 0px", lineHeight: 0.9 }}>
              <i style={{ fontSize: isApp ? 10 : 13 }}>
                Vui lòng đăng nhập vào app ngân hàng và <br />
                quét mã QR để thanh toán
              </i>
            </div>
            {!dataStep2?.info?.QR &&
            !dataStep2?.info?.qr_code_url &&
            !dataStep2?.info?.qr_code_image ? (
              <CircularProgress />
            ) : (
              <img
                style={{ borderRadius: 16 }}
                width="70%"
                alt="qr"
                src={
                  dataStep2?.info?.QR ||
                  dataStep2?.info?.qr_code_url ||
                  "data:image/jpeg;base64," + dataStep2?.info?.qr_code_image
                }
              />
            )}
            <div style={{ ...css.boxBtn, marginTop: 10 }}>
              {/* <div style={{ ...css.btn, marginRight: 5 }}>
                <img src={LinkApp} style={{ marginRight: 5 }} />
                Mở app ngân hàng
              </div> */}
              <div
                onClick={() => {
                  dispatch("downloadQr");
                }}
                style={{ ...css.btn, marginLeft: 5 }}
              >
                <img src={Dowload} style={{ marginRight: 5 }} />
                Tải mã code
              </div>
            </div>
          </div>
          <div style={{ margin: "10px 0px" }}>
            <table>
              <tr>
                <td style={{ width: "47%" }}>
                  <hr />
                </td>
                <td
                  style={{
                    verticalAlign: "middle",
                    textAlign: "center",
                    fontSize: 12,
                    color: "#646D69",
                  }}
                >
                  <b>Hoặc</b>
                </td>
                <td style={{ width: "47%" }}>
                  <hr />
                </td>
              </tr>
            </table>
          </div>
          <div style={{ ...css.boxQR, background: "#fff", color: "#3A3A3A" }}>
            <div>
              <b style={{ color: "#25806D" }}>Thông tin chuyển khoản</b>
            </div>
            <div style={{ margin: "5px 0px 10px 0px", lineHeight: 0.9 }}>
              <i style={{ fontSize: isApp ? 10 : 13 }}>
                Vui lòng thực hiện chuyển khoản theo các <br />
                thông tin sau
              </i>
            </div>
            <div style={{ textAlign: "left", fontSize: 13 }}>
              <div>Ngân hàng</div>
              <div>
                {dataTran?.method === "9pay" && (
                  <div style={css.value}>{info?.name}</div>
                )}
                {dataTran?.method === "transfer2" && (
                  <div style={css.value}>{info?.bankName}</div>
                )}
                {dataTran?.method === "transfer1" && <img src={ImgWooribank} />}
              </div>
            </div>
            <div style={css.divItem}>
              <div>Tên tài khoản:</div>
              {info?.bank?.account_name && (
                <div style={css.value}>{info?.bank?.account_name}</div>
              )}
              {info?.account_name && (
                <div style={css.value}>{info?.account_name}</div>
              )}
            </div>
            <div style={css.divItem}>
              <div>Số tài khoản:</div>
              {info?.account_number && (
                <div style={css.value}>
                  <div>{info?.account_number}</div>
                  <CopyToClipboard
                    onCopy={() => message.success("Sao chép thành công")}
                    text={info?.account_number}
                  >
                    <div style={css.btnCopy}>
                      <img src={Copy} style={{ marginRight: 3 }} />
                      Sao chép
                    </div>
                  </CopyToClipboard>
                </div>
              )}
              {info?.bank?.account_no && (
                <div style={css.value}>
                  <div>{info?.bank?.account_no}</div>
                  <CopyToClipboard
                    onCopy={() => message.success("Sao chép thành công")}
                    text={info?.bank?.account_no}
                  >
                    <div style={css.btnCopy}>
                      <img src={Copy} style={{ marginRight: 3 }} />
                      Sao chép
                    </div>
                  </CopyToClipboard>
                </div>
              )}
              {info?.account_no && (
                <div style={css.value}>
                  <div>{info?.account_no}</div>
                  <CopyToClipboard
                    onCopy={() => message.success("Sao chép thành công")}
                    text={info?.account_no}
                  >
                    <div style={css.btnCopy}>
                      <img src={Copy} style={{ marginRight: 3 }} />
                      Sao chép
                    </div>
                  </CopyToClipboard>
                </div>
              )}
            </div>
            <div style={css.divItem}>
              {dataTran?.method !== "transfer2" && (
                <div>Nội dung chuyển khoản:</div>
              )}
              {info?.bank?.map_id && (
                <div style={css.value}>
                  {info?.bank?.map_id}
                  <CopyToClipboard
                    onCopy={() => message.success("Sao chép thành công")}
                    text={info?.bank?.map_id}
                  >
                    <div style={css.btnCopy}>
                      <img src={Copy} style={{ marginRight: 3 }} />
                      Sao chép
                    </div>
                  </CopyToClipboard>
                </div>
              )}
              {info?.content && (
                <div style={css.value}>
                  {info?.content}
                  <CopyToClipboard
                    onCopy={() => message.success("Sao chép thành công")}
                    text={info?.content}
                  >
                    <div style={css.btnCopy}>
                      <img src={Copy} style={{ marginRight: 3 }} />
                      Sao chép
                    </div>
                  </CopyToClipboard>
                </div>
              )}
            </div>
            <div style={css.divItem}>
              <div>Số tiền thanh toán VNĐ:</div>
              <div style={css.value}>
                {formatMoney(info?.bank?.amount) || formatMoney(info?.amount)}
                <CopyToClipboard
                  onCopy={() => message.success("Sao chép thành công")}
                  text={info?.bank?.amount || info?.amount}
                >
                  <div style={css.btnCopy}>
                    <img src={Copy} style={{ marginRight: 3 }} />
                    Sao chép
                  </div>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div>
            <div style={{ marginTop: 10, fontSize: 14 }}>
              Thanh toán Internet Banking
            </div>
            <table style={{ color: "#646D69", padding: "5px 0px" }}>
              <tr>
                <td
                  style={{ verticalAlign: "top", padding: "5px 5px 5px 0px" }}
                >
                  <img src={I1} />
                </td>
                <td style={{ fontSize: 11, padding: "5px 0px" }}>
                  Mở ứng dụng/ web chuyển khoản ngân hàng của bạn để tiến hành
                  chuyển tiền
                </td>
              </tr>
              <tr>
                <td
                  style={{ verticalAlign: "top", padding: "5px 5px 5px 0px" }}
                >
                  <img src={I2} />
                </td>
                <td style={{ fontSize: 11, padding: "5px 0px" }}>
                  Nhập chính xác các thông tin chuyển khoản bên trên và tiến
                  hành thanh toán.
                </td>
              </tr>
              <tr>
                <td style={{ verticalAlign: "top", padding: "5px 0px" }}>
                  <img src={I3} />
                </td>
                <td style={{ fontSize: 11, padding: "5px 0px" }}>
                  Xác nhận đã chuyển khoản
                  <br /> Sau khi thanh toán thành công, bạn bấm{" "}
                  <b style={{ color: "#3A3A3A" }}>
                    “xác nhận đã chuyển khoản"
                  </b>{" "}
                  để nhận Giấy chứng nhận bảo hiểm
                  <br />
                  <div
                    style={{
                      textDecoration: "underline",
                      color: "#3A3A3A",
                      marginTop: 5,
                    }}
                  >
                    <b>Lưu ý:</b>
                  </div>
                  <div style={{ marginTop: 3 }}>
                    Nếu có lỗi xảy ra vui lòng liên hệ với chúng tôi qua
                    <br />
                    hotline:{" "}
                    <a
                      style={{ color: "#009B4B", textDecoration: "none" }}
                      href="tel:1900292987"
                    >
                      1900 292 987
                    </a>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div className={classes.empty}></div>
          {!isApp && (
            <div className={classes.centerItem}>
              <Button
                className={classes.confirmBtn}
                style={{ background: "#009B4B", color: "#fff" }}
                onClick={() => dispatch("showConfirm")}
              >
                Xác nhận đã chuyển khoản
              </Button>
            </div>
          )}
        </Box>
        {isApp && (
          <Box className={classes.button}>
            {/* <Button
              className={classes.confirmBtn}
              style={{ background: "#fff", color: "#009B4B", marginRight: 10}}
              onClick={() => window.location.replace(dataTran?.repaymentLink)}
            >
              Quay lại
            </Button> */}
            <Button
              className={classes.confirmBtn}
              style={{ background: "#009B4B", color: "#fff" }}
              onClick={() => dispatch("showConfirm")}
            >
              Xác nhận đã chuyển khoản
            </Button>
          </Box>
        )}
        <ConfirmModal open={openConfirm} dispatch={dispatch} />
        <Waiting loading={openWaiting} />
      </div>
    </div>
  );
}
