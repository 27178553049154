import moment from "moment";

export const getParamUrlByKey = (key) => {
  if (!key) return;
  const search = window.location.search;
  const tmp = search.split("?");
  const params = new URLSearchParams(tmp[1]);
  return params.get(key);
};

export const formatMoney = (money) => {
  if (!money) return 0;
  const res = `${money || 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return res;
};

export const formatDate = (date) => {
  if (!date) return "";
  const newDate = moment(date).format("DD/MM/YYYY");
  return newDate !== "Invalid date" ? newDate : "--";
};

export const renderSelectMethod = (method) => {
  switch (method) {
    case "transfer1":
      return "Chuyển khoản";
    case "transfer2":
      return "Chuyển khoản";
    case "9pay":
      return "Chuyển khoản";
    case "atm":
      return "Thẻ ATM";
    case "cc":
      return "Thẻ Visa/ Master Card/ JCB";
    case "moca":
      return "Ví Moca";
    case "galaxy-pay":
      return "Galaxy Pay";
    default:
      return "Qua đối tác";
  }
};
