import { useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { getParamUrlByKey } from "../../extra/utils";
import useReducer from "../../library/hooks/useReducer";
import Loading from "./components/Loading";
import Waiting from "./components/Waiting";
import paymentReducer from "./paymentReducer";
import Step1 from "./page/step1";
import Step2 from "./page/step2";
import Step3 from "./page/step3";
import Step4 from "./page/step4";

const initialState = {
  currentStep: 0,
  dataStep1: {},
  dataStep2: {},
  loading: false,
  loadingQr: false,
  openConfirm: false,
  openWaiting: false,
  openChooseMethost: false,
  sum: 0,
  openMdlink: false,
  openConfirmPass: false,
  errConfirmPass: "",
};

const renderPage = (
  currentStep,
  { state, dispatch, isApp, smScreen, code }
) => {
  switch (currentStep) {
    case 1:
      return <Step1 {...{ state, dispatch, isApp, smScreen }} />;
    case 2:
      return <Step2 {...{ state, dispatch, isApp }} />;
    case 3:
      return <Step3 {...{ state, dispatch, isApp, code }} />;
    case 4:
      return <Step4 {...{ state, dispatch, isApp, code }} />;
    default:
      return <div></div>;
  }
};

export default function Payment() {
  const code = getParamUrlByKey("code");
  const resultCd = getParamUrlByKey("resultCd");
  // trên URL do đối tác bán sản phẩm trả về
  const waiting = getParamUrlByKey("waiting") || false;
  // trên URL do cổng thanh toán megapay trả về
  const success = resultCd === "00_000";
  const isApp = useMediaQuery("(max-width: 1023px)");
  const smScreen = useMediaQuery("(max-width: 1025px)");

  const [state, dispatch] = useReducer({
    reducer: paymentReducer,
    name: "paymentReducer",
    initialState,
  });

  useEffect(() => {
    if(!resultCd && !waiting){
      dispatch("initial");
    }else{
      dispatch("openSuccess")
    }
    // !success && !waiting ? dispatch("initial") : dispatch("openSuccess");
  }, []);

  const { loading, currentStep, openWaiting } = state;
  return (
    <>
      <Loading loading={loading} />
      <Waiting loading={openWaiting} />
      {renderPage(currentStep, { state, dispatch, isApp, smScreen, code })}
    </>
  );
}
