import { Box } from "@mui/material";
import { Button } from "antd";
import IFail from "../assets/icon/fail.svg";
import IWaiting from "../assets/icon/warning.svg";
import { useStyles } from "../styles";
import { formatMoney, renderSelectMethod } from "./.././../../extra/utils";

export default function Step4({ state, dispatch, isApp, code }) {
  const classes = useStyles();
  const { status = null } = state;
  const dataTran = JSON.parse(localStorage.getItem("dataTran"));

  return (
    <div>
      <Box className={classes.failBox}>
        <img
          src={status === 2 ? IFail : IWaiting}
          width={60}
          alt="icon"
        />
        <>
          <div className="content">
            <div
              style={{
                fontSize: 18,
                fontWeight: 700,
                color: "#3A3A3A",
                margin: "5px 0px",
              }}
            >
              {status === 0
                ? "Chờ thanh toán"
                : status === 2
                ? "Thanh toán không thành công"
                : "Chờ thanh toán"}
            </div>
            <div style={css.boxItem}>
              <div style={{ ...css.leftContent, minWidth: 80 }}>
                Mã giỏ hàng
              </div>
              <div style={css.rigthItem}>{dataTran?.code}</div>
            </div>
            <div style={css.boxItem}>
              <div style={{ ...css.leftContent, minWidth: 130 }}>
                Hình thức thanh toán
              </div>
              <div style={css.rigthItem}>
                {dataTran?.free
                  ? "Tiền thưởng"
                  : renderSelectMethod(dataTran?.method)}
              </div>
            </div>
            <div style={css.boxItem}>
              <div style={{ ...css.leftContent, minWidth: 80 }}>Tổng tiền</div>
              <div style={css.rigthItem}>{formatMoney(dataTran?.fees)}</div>
            </div>
            <div
              style={{
                fontSize: 13,
                marginTop: 30,
                color: "#646D69",
                display:status === 2 ? "none" : "",
              }}
            >
              Vui lòng liên hệ nhân viên bán hàng hoặc
              <br /> chăm sóc khách hàng để được hỗ trợ.
            </div>
          </div>
        </>
        <div className="empty"></div>
        <Button
          className={classes.btnPayBack}
          style={{ display: status === 2 ? "" : "none" }}
          onClick={() => window.location.replace(dataTran?.repaymentLink)}
        >
          Thanh toán lại
        </Button>
        <Button
          className={classes.failBtn}
          onClick={() => dispatch("continue")}
        >
          Tiếp tục mua hàng
        </Button>
      </Box>
      <div
        style={{
          position: "absolute",
          bottom: 30,
          lineHeight: 1.5,
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div style={{ width: "100%", textAlign: "center" }}>
          <div style={{ fontSize: 12, color: "#3A3A3A" }}>
            Bạn cần hỗ trợ mua hàng. Vui lòng liên hệ
          </div>
          <div style={{ fontSize: 13, color: "#3A3A3A", fontWeight: 700 }}>
            Hotline:{" "}
            <a
              style={{ color: "#25806D", textDecoration: "none" }}
              href="tel:1900292987"
            >
              1900 29 29 87
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
const css = {
  boxItem: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderBottom: "1px solid #D9D9D9",
    padding: "10px 0px",
  },
  leftContent: {
    fontSize: 12,
    color: "#646D69",
    textAlign: "left",
  },
  rigthItem: {
    fontSize: 14,
    color: "#3A3A3A",
    textAlign: "right",
    fontWeight: 500,
  },
};
