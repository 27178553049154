import api from ".";
import * as config from "./../../extra/config"
import { getParamUrlByKey } from "../../extra/utils";

const payment_url = config.payment_url;

// code là mã giỏ hàng
export function getTransaction(code) {
  return api.apiForm("GET", `/api/transaction/${code}`);
}

export function payment(paidBy, code, password) {
  return api.apiForm(
    "GET",
    `/api/pay/${paidBy}/${code}?password=${password}&token=${getParamUrlByKey('token')}&callback=${encodeURIComponent(
      `${payment_url}?code=${code}`
    )}`
  );
}

export function statusTransaction(code) {
  return api.apiForm("GET", `/api/transaction/${code}/status`);
}

export function getCompleteInfo(code) {
  return api.apiForm("GET", `/api/transaction/${code}/complete`);
}

export function getDetail9Pay(code) {
  return api.apiForm("GET", `/api/pay/9pay/${code}`);
}

export function getDetailGPay(code) {
  return api.apiForm("GET", `/api/pay/gpay/${code}`);
}
