import { Backdrop, CircularProgress } from "@mui/material";

const Loading = ({ loading }) => {
  return (
    <Backdrop style={{ zIndex: 99, color: "#fff" }} open={!!loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;
