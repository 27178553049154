import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PUBLIC_ROUTE } from "./route-constants";
import Payment from "../../views/payment";

const routes = [
  {
    path: PUBLIC_ROUTE.PAYMENT,
    exact: true,
    component: () => <Payment />,
  },
];

export default function AppRouter() {
  return (
    <Suspense fallback={<div></div>}>
      <BrowserRouter>
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              element={<route.component />}
            ></Route>
          ))}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}
