import { Box } from "@mui/material";
import { Button } from "antd";
import { useStyles } from "../styles";
import Isuccess from "../assets/icon/success.svg";
import { formatMoney, renderSelectMethod } from "./.././../../extra/utils";
import MdShowlink from "../components/mdShowlink";

export default function Step3({ state, dispatch, isApp, code }) {
  const { dataStep2 } = state;
  const classes = useStyles();
  const dataTran = JSON.parse(localStorage.getItem("dataTran"));
  
  return (
    <div style={{ minHeight: "100vh" }}>
      <MdShowlink {...{ dispatch, isApp, state }} />
      <Box className={classes.failBox}>
        <img src={Isuccess} width={60} alt="icon" />
        <>
          <div className="content">
            <div
              style={{
                fontSize: 18,
                fontWeight: 700,
                color: "#3A3A3A",
                // margin: "10px 0px",
              }}
            >
              Thanh toán thành công
            </div>
            <div style={css.boxItem}>
              <div style={{ ...css.leftContent, minWidth: 80 }}>
                Mã giỏ hàng
              </div>
              <div style={css.rigthItem}>{dataTran?.code}</div>
            </div>
            <div style={css.boxItem}>
              <div style={{ ...css.leftContent, minWidth: 130 }}>
                Hình thức thanh toán
              </div>
              <div style={css.rigthItem}>
                {dataTran?.free
                  ? "Tiền thưởng"
                  : renderSelectMethod(dataTran?.method)}
              </div>
            </div>
            <div style={css.boxItem}>
              <div style={{ ...css.leftContent, minWidth: 80 }}>Tổng tiền</div>
              <div style={css.rigthItem}>{formatMoney(dataTran?.fees)}</div>
            </div>
          </div>
          <div style={{ fontSize: 13, color: "#646D69", margin: "10px 0px" }}>
            Chúng tôi đã gửi SMS vào số điện thoại mà <br /> Quý Khách đã đăng
            ký.
          </div>
        </>
        <Button
          className={classes.confirmBtn}
          onClick={() => dispatch("continue")}
        >
          Tiếp tục mua hàng
        </Button>
      </Box>
      <div
        style={{
          position: "absolute",
          bottom: 10,
          lineHeight: 1.5,
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div style={{ width: "100%", textAlign: "center", padding: "0px 6%" }}>
          <div style={{ fontSize: 12, color: "#3A3A3A" }}>
            Vui lòng kiểm tra tin nhắn hoặc truy cập <br /> website
            <a
              href={dataStep2?.completeInfo?.tracuu || "//tracuu.globalcare.vn"}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <b style={{ color: "#009B4B" }}> tracuu.globalcare.vn</b>
            </a>{" "}
            để tra cứu
            <br /> và tải giấy chứng nhận bảo hiểm.
          </div>
          <img
            width="90px"
            height="90px"
            alt="qr"
            style={{ margin: "10px 0px" }}
            src={dataStep2.completeInfo?.qr || ""}
          />
          <div style={{ fontSize: 12, color: "#3A3A3A" }}>
            Chụp lại mã QR để thuận tiện tra cứu.
          </div>
        </div>
      </div>
    </div>
  );
}

const css = {
  boxItem: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderBottom: "1px solid #D9D9D9",
    padding: "10px 0px",
  },
  leftContent: {
    fontSize: 12,
    color: "#646D69",
    textAlign: "left",
  },
  rigthItem: {
    fontSize: 14,
    color: "#3A3A3A",
    textAlign: "right",
    fontWeight: 500,
  },
};
