import api from ".";
import * as config from "./../../extra/config";
import { getParamUrlByKey } from "../../extra/utils";

const payment_url = config.payment_url;
const params = `utm_source=product-hub&product_code=sk`

// code là mã giỏ hàng
export function getTransactionProducthub(code) {
  return api.apiForm("GET", `/api/product-hub/transaction/${code}?${params}`);
}

export function paymentProducthub(paidBy, code, password) {
  return api.apiForm(
    "GET",
    `/api/product-hub/pay/${paidBy}/${code}?${params}&password=${password}&token=${getParamUrlByKey(
      "token"
    )}&callback=${encodeURIComponent(`${payment_url}?code=${code}`)}`
  );
}

export function statusTransactionProducthub(code) {
  return api.apiForm("GET", `/api/product-hub/transaction/${code}/status?${params}`);
}

export function getCompleteInfoProducthub(code) {
  return api.apiForm("GET", `/api/product-hub/transaction/${code}/complete?${params}`);
}
