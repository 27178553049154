import { Dialog, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Button } from "antd";
import React from "react";
import IWarning from "../assets/icon/warning.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmModal({ open, dispatch }) {
  const classes = styles();

  return (
    <Dialog
      open={!!open}
      TransitionComponent={Transition}
      onClose={() => dispatch("closeConfirm")}
    >
      <div className={classes.container}>
        <img src={IWarning} width={60} />
        <div className={classes.title}>Chú ý</div>
        <div style={{ fontSize: 16, marginTop: 10, color: "#515855" }}>
          Nếu chưa chuyển khoản chọn{" "}
          <span style={{ color: "#009B4B" }}>"Trở lại"</span> để thực hiện
          chuyển khoản. Đã chuyển khoản thành công chọn{" "}
          <span style={{ color: "#009B4B" }}>“Xác nhận"</span> để hoàn tất thanh
          toán và nhận đơn bảo hiểm.
        </div>
        <div className={classes.wrapperBtn}>
          <Button
            className={classes.btnBack}
            onClick={() => dispatch("closeConfirm")}
          >
            Trở lại
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => dispatch("call")}
          >
            Xác nhận
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

const styles = makeStyles(() => ({
  container: { textAlign: "center", padding: "16px" },
  title: { fontSize: 22, marginTop: 10, color: "#383D3B", fontWeight: 600 },
  content: {},
  wrapperBtn: {
    marginTop: 15,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  button: {
    backgroundColor: "#009B4B",
    width: "48%",
    height: "100%",
    color: "white",
    borderRadius: "8px",
    padding: "8px 0",
    fontSize: 16,
    fontWeight: 600,
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#25806D ",
    },
  },
  btnBack: {
    width: "48%",
    height: "100%",
    border: "1px solid #009B4B",
    color: "#009B4B",
    borderRadius: "8px",
    padding: "8px 0",
    fontSize: 16,
    fontWeight: 600,
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
}));
