import React from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "antd";

const BottomButton = (props) => {
  const { title = "", onClick, onBack, disabled = false } = props;
  const classes = styles();

  return (
    <div className={classes.container}>
      {/* {onBack && (
        <Button
          disabled={!!disabled}
          className={classes.btnBack}
          onClick={onBack}
        >
          Quay lại
        </Button>
      )} */}
      {onClick && (
        <Button
          disabled={!!disabled}
          className={classes.button}
          onClick={onClick}
          ghost={false}
          // variant="contained"
        >
          {title}
        </Button>
      )}
    </div>
  );
};

const styles = makeStyles(() => ({
  container: {
    position: "sticky",
    bottom: 0,
    left: 0,
    boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.15)",
    alignItems: "center",
    zIndex: 12,
    background: "#fff",
    borderRadius: "16px 16px 0px 0px",
    display: "flex",
    justifyContent: "space-between",
    padding: "15px 10px",
  },

  button: {
    backgroundColor: "#009B4B",
    width: "100%",
    height: "100%",
    color: "white",
    borderRadius: 7,
    padding: "8px 0",
    fontSize: 16,
    // fontWeight: 600,
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    color:'#FFFFFF !important',
    border: '1px solid #009B4B !important'
  },
  btnBack: {
    width: "48%",
    height: "100%",
    border: "1px solid #25806D",
    color: "#25806D",
    borderRadius: "16px",
    padding: "8px 0",
    fontSize: 16,
    fontWeight: 600,
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    // "&:hover": {
    //   backgroundColor: "transparent !important",
    // },
  },
}));

export default BottomButton;
