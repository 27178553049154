import { Dialog, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Button } from "antd";
import React from "react";
import IClose from "../assets/icon/close.svg";
import Ichoose from "../assets/icon/choose.svg";
import Inochoose from "../assets/icon/noChoose.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PaymentMethods({ open, dispatch, state }) {
  const { openChooseMethost, dataStep1 } = state;
  const classes = styles();

  return (
    <Dialog
      open={openChooseMethost}
      TransitionComponent={Transition}
      className="dialogBottom"
    >
      <div
        className={classes.container}
      >
        <div style={{ color: "#3A3A3A" }}>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10}}>
            <b>Chọn phương thức thanh toán</b>
            <img
              onClick={() => {
                dispatch("closeChooseMethots");
              }}
              src={IClose}
              style={{ width: 15, cursor:'pointer'}}
            />
          </div>
        </div>
      </div>
      {(dataStep1.cardTypes || []).map((data, i) => (
        <div
          key={i}
          style={{
            borderBottom:
              i + 1 === dataStep1.cardTypes.length ? "" : "1px solid #DCDCDC",
            padding: "16px 16px",
            display: "flex",
            alignItems: "center",
            fontSize: 15,
            marginBottom: i + 1 === dataStep1.cardTypes.length ? 15 : 0,
          }}
          onClick={() => {
            dispatch({ type: "selectCard", id: data?.id });
            dispatch("closeChooseMethots");
          }}
          
        >
          <img
            src={dataStep1?.sl === data?.id ? Ichoose : Inochoose}
            style={{ marginRight: 10 }}
          />
          <img
            src={data?.icon}
            style={{
              marginRight: 10,
              width: data?.id === "transfer1" ? 32 : 28,
            }}
          />
          <span>{data?.name}</span>
        </div>
      ))}
    </Dialog>
  );
}

const styles = makeStyles(() => ({
  container: { padding: "16px 16px 5px 16px", fontSize: 15 }
}));
