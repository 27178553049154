import { message } from "antd";
import { saveAs } from "file-saver";
import {
  getCompleteInfo,
  getTransaction,
  payment,
  statusTransaction,
  getDetail9Pay,
  getDetailGPay,
} from "../../controller/api/api_payment";
import { getParamUrlByKey } from "../../extra/utils";
import ImgMoca from "./assets/images/grab-moca.svg";
import ImgMocaActive from "./assets/images/grab-moca-active.svg";
import ImgViNoiBo from "./assets/images/vinoibo.svg";
import ImgMega from "./assets/icon/chuyenkhoan.svg";
import Img9pay from "./assets/icon/logo9pay.png";
import IGalaxy from "./assets/icon/galaxypay.jpeg";
import ImgMegaActive from "./assets/icon/chuyenkhoanActive.svg";
import ImgGpay from "./assets/images/gpay.png";
import ImgVisa from "./assets/icon/visa.svg";
import ImgVisaActive from "./assets/icon/visaActive.svg";
import ImgATM from "./assets/icon/atm.svg";
import ImgATMActive from "./assets/icon/atmActive.svg";
import ImgMomo from "./assets/images/momo.png";
import {
  getCompleteInfoProducthub,
  getTransactionProducthub,
  paymentProducthub,
  statusTransactionProducthub,
} from "../../controller/api/api_producthub_payment";

const cardTypes = [
  {
    id: "transfer1",
    name: "Chuyển khoản",
    icon: ImgMega,
    iconActive: ImgMegaActive,
    value: "transfer mega",
  },
  {
    id: "transfer2",
    name: "Chuyển khoản",
    icon: ImgGpay,
    iconActive: ImgGpay,
    value: "gpay transfer",
  },
  {
    id: "9pay",
    name: "Chuyển khoản",
    icon: Img9pay,
    iconActive: Img9pay,
    value: "9pay",
  },
  {
    id: "atm",
    name: "Thẻ ATM",
    icon: ImgATM,
    iconActive: ImgATMActive,
    value: "atm mega",
  },
  {
    id: "cc",
    name: "Thẻ Visa/ Master Card/ JCB",
    icon: ImgVisa,
    iconActive: ImgVisaActive,
    value: "cc mega",
  },
  {
    id: "moca",
    name: "Ví Moca",
    icon: ImgMoca,
    iconActive: ImgMocaActive,
    value: "moca",
  },
  {
    id: "galaxy-pay",
    name: "Galaxy Pay",
    icon: IGalaxy,
    iconActive: IGalaxy,
    value: "galaxy pay",
  },
  { id: "test", name: "Test", icon: ImgMomo },
];

function delay(code, state) {
  const callApi = async () => {
    const isProducthub =
      getParamUrlByKey("goodsNm") === "product_hub_health" ||
      getParamUrlByKey("utm_source") === "product-hub";
    const res = isProducthub
      ? await statusTransactionProducthub(code)
      : await statusTransaction(code);
    if (res instanceof Error) {
      // return message.error("Có lỗi xảy ra", 2);
      return;
    }
    const stt = res.data?.data?.status;
    //0:err 1:success 2:fail
    return { status: stt };
  };
  return new Promise((resolve) =>
    setTimeout(
      () => {
        const res = callApi();
        resolve(res);
      },
      state.sum === 1 || state.sum === 0 ? 0 : 30000
    )
  );
}

export default function paymentReducer({ state, dispatch, action }) {
  //get utm_source=product-hub
  const isProducthub = getParamUrlByKey("utm_source") === "product-hub";

  //data thanh toán
  const dataTran = JSON.parse(localStorage.getItem("dataTran"));
  const code = getParamUrlByKey("code");
  const cases = {
    setLocalStorageDataTran: () => {
      const { dataStep1 } = state;
      localStorage.setItem(
        "dataTran",
        JSON.stringify({
          fees: +dataStep1?.transaction?.total,
          method: dataStep1?.sl,
          code: getParamUrlByKey("code"),
          repaymentLink: window.location.href,
          //link tiếp tục mua hàng trên url nếu có thì đá
          continueShopping: getParamUrlByKey("callback"),
          free: dataStep1.free,
          platform: getParamUrlByKey("platform"),
        })
      );
    },
    onLoading: () => ({ loading: true }),
    initial: async () => {
      const { dataStep1 } = state;
      dispatch("onLoading");

      const response1 = isProducthub
        ? await getTransactionProducthub(code)
        : await getTransaction(code);
      if (response1 instanceof Error) {
        // return message.error("Có lỗi xảy ra", 2);
        return;
      }
      dataStep1.transaction = response1?.data?.data || {};
      dataStep1.order = response1?.data?.data?.products?.order || [];
      dataStep1.method = response1?.data?.data?.method || [];
      dataStep1.cardTypes = dataStep1.method.map((item) => {
        const a = cardTypes.find((i) => i.value === item);
        return a;
      });
      dataStep1.card = dataStep1.cardTypes[0];
      dataStep1.sl = dataStep1?.card?.id;
      // Nếu là đơn 0 đồng là true
      dataStep1.free = +dataStep1?.transaction?.total === 0 ? true : false;
      return { currentStep: 1, dataStep1, loading: false };
    },
    selectCard: () => {
      const { dataStep1 } = state;
      const { id } = action;
      dataStep1.card = dataStep1.cardTypes.find((i) => i.id === id);
      dataStep1.sl = id;
      return { dataStep1 };
    },
    setPassWord: () => {
      const { dataStep1 } = state;
      const { password } = action;
      dataStep1.password = password;
      return { dataStep1 };
    },
    downloadQr: () => {
      const { dataStep2 } = state;
      const qr =
        dataStep2.info?.QR ||
        dataStep2?.info?.qr_code_url ||
        "data:image/jpeg;base64," + dataStep2?.info?.qr_code_image;
      saveAs(qr, "QR-CODE.jpg");
      window.ReactNativeWebView.postMessage(`/gotoBrownser?link=${qr}`);
    },
    // Xử lý chuyển khoản megapay
    transfer: async () => {
      const { dataStep1, dataStep2 } = state;
      dispatch("onLoading");
      const res = isProducthub
        ? await paymentProducthub("transfer", code, dataStep1?.password)
        : await payment("transfer", code, dataStep1?.password);
      if (res instanceof Error) {
        if (res?.response?.data?.password) {
          setTimeout(() => {
            dispatch("openConfirmPass");
          }, 2000);
        }
        return { errConfirmPass: res?.response?.data?.mess, loading: false };
      }
      // Nếu là đơn 0 đồng thí checkstatus và done
      if (dataStep1.free) {
        dispatch("openSuccess");
        return;
      }
      dataStep2.info = res?.data || {};
      dataStep2.timeEnd = dataStep1?.transaction?.timeEnd || "";
      return {
        dataStep2,
        loading: false,
        currentStep: 2,
        errConfirmPass: "",
      };
    },
    // Xử lý chuyển khoản 9pay
    transfer9pay: async () => {
      const { dataStep1, dataStep2 } = state;
      dispatch("onLoading");
      const res = await getDetail9Pay(code);
      if (res instanceof Error) {
        // return message.error("Có lỗi xảy ra", 2);
        return;
      }
      dataStep2.info = res?.data?.data || {};
      dataStep2.timeEnd = dataStep1?.transaction?.timeEnd || "";
      return { dataStep2, loading: false, currentStep: 2 };
    },
    // Xử lý chuyển khoản Gpay
    transferGpay: async () => {
      const { dataStep1, dataStep2 } = state;
      dispatch("onLoading");
      const res = await getDetailGPay(code);
      if (res instanceof Error) {
        // return message.error("Có lỗi xảy ra", 2);
        return;
      }
      dataStep2.info = res?.data?.data || {};
      dataStep2.timeEnd = dataStep1?.transaction?.timeEnd || "";
      return { dataStep2, loading: false, currentStep: 2 };
    },
    // Xử lý thanh toán visa,atm, moca, galaxy
    directPayment: async () => {
      const { dataStep1 } = state;
      const paidBy = dataStep1?.card?.id || "";
      dispatch("onLoading");
      const res = isProducthub
        ? await paymentProducthub(paidBy, code, dataStep1?.password)
        : await payment(paidBy, code, dataStep1?.password);
      if (res instanceof Error) {
        if (res?.response?.data?.password) {
          setTimeout(() => {
            dispatch("openConfirmPass");
          }, 2000);
        }
        return { errConfirmPass: res?.response?.data?.mess, loading: false };
      }
      const directUrl =
        res?.data?.data?.redirect || res?.data?.redirect || null;
      if (!directUrl) return;
      window.location.href = directUrl;
      return { openConfirmPass: false, errConfirmPass: "" };
    },
    payment: async () => {
      const { dataStep1 } = state;
      if (["transfer1"].includes(dataStep1.sl)) {
        dispatch("transfer");
      }
      if (["transfer2"].includes(dataStep1.sl)) {
        dispatch("transferGpay");
      }
      if (["9pay"].includes(dataStep1.sl)) {
        dispatch("transfer9pay");
      }
      if (["atm", "cc", "moca", "galaxy-pay"].includes(dataStep1.sl)) {
        dispatch("directPayment");
      }
    },
    call: () => {
      dispatch("closeConfirm");
      dispatch("loadingWaiting");
      return { sum: (state.sum || 0) + 1 };
    },
    checkStatus: async () => {
      const result = await delay(code, state);
      if (result?.status === 1) {
        dispatch("getComplete");
        return { openWaiting: false, currentStep: 3, loading: false };
      }
      if (state.sum === 2) {
        return {
          openWaiting: false,
          currentStep: 4,
          status: result?.status,
          loading: false,
        };
      }
      if (result?.status !== 1 && state.dataStep1.sl !== "transfer1") {
        dispatch("checkStatus");
      }
      return { sum: (state.sum || 1) + 1 };
    },
    loadingWaiting: () => ({ openWaiting: true }),
    showConfirm: () => ({ openConfirm: true }),
    closeConfirm: () => ({ openConfirm: false }),
    showModalLink: () => ({ openMdlink: true }),
    closeModalLink: () => ({ openMdlink: false }),
    openChooseMethots: () => ({ openChooseMethost: true }),
    closeChooseMethots: () => ({ openChooseMethost: false }),
    openConfirmPass: () => ({ openConfirmPass: true }),
    closeConfirmPass: () => ({ openConfirmPass: false }),
    continue: async () => {
      if (dataTran?.continueShopping) {
        window.location.replace(dataTran?.continueShopping);
        localStorage.clear();
        // return;
      }
      localStorage.clear();
      window.ReactNativeWebView.postMessage(`/gotohome`);
    },
    openSuccess: async () => {
      dispatch("loadingWaiting");
      dispatch("checkStatus");
      return { sum: (state.sum || 1) + 1 };
    },
    getComplete: async () => {
      const { dataStep2 } = state;
      dispatch("onLoading");
      const producthub = getParamUrlByKey("goodsNm") === "product_hub_health";
      const res = producthub
        ? await getCompleteInfoProducthub(code)
        : await getCompleteInfo(code);
      if (res?.status !== 200) {
        // return message.error("Có lỗi xảy ra", 2);
        return;
      }
      if (res?.data?.data?.captureLink?.length > 0) {
        dispatch("showModalLink");
      }

      dataStep2.completeInfo = res?.data?.data || {};
      return { currentStep: 3, dataStep2, loading: false };
    },
  };
  return cases[action?.type];
}
