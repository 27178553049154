import { Dialog, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { message, Button } from "antd";
import React from "react";
import IClose from "../assets/icon/close.svg";
import IWarning from "../assets/images/warning2.svg";
import Ilink from "../assets/images/link.svg";
import { getParamUrlByKey } from "../../../extra/utils";
import Copy from "./../assets/icon/copy2.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MdShowlink({ open, dispatch, state }) {
  const { dataStep1, dataStep2, openMdlink } = state;
  const classes = styles();
  const platform = getParamUrlByKey("platform");
  const dataTran = JSON.parse(localStorage.getItem("dataTran"));

  const getParam = (link, key) => {
    if (!link || !key) return;
    const tmp = link.split("?");
    const params = new URLSearchParams(tmp[1]);
    return params.get(key);
  };

  return (
    <Dialog
      open={openMdlink}
      TransitionComponent={Transition}
      className="dialogCenter"
    >
      <div className={classes.container}>
        <div style={{ color: "#3A3A3A" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: 18,
              alignItems: "flex-start",
            }}
          >
            <div style={{ textAlign: "center", width: "100%", color: "red" }}>
              {/* <b>Chú ý</b> */}
              <img
                onClick={() => {
                  dispatch("closeModalLink");
                }}
                src={IWarning}
                style={{ width: 70 }}
              />
            </div>
            <img
              onClick={() => {
                dispatch("closeModalLink");
              }}
              src={IClose}
              style={{ width: 15 }}
            />
          </div>
        </div>
        <div
          style={{
            marginTop: 10,
            textAlign: "center",
            fontSize: 16,
            lineHeight: 1.2,
          }}
        >
          Vui lòng vào link này để chụp hình ảnh <br /> xe và hoàn tất hợp đồng
          theo hướng dẫn
        </div>
      </div>
      {(dataStep2?.completeInfo?.captureLink || []).map((data, i) => (
        <div key={i} className={classes.link}>
          <div
            onClick={() => {
              if (platform === "app" || dataTran?.platform === "app") {
                window.ReactNativeWebView.postMessage(
                  `/gotoBrownser?link=${data}`
                );
                return;
              }
              window.open(data, "_blank");
            }}
            style={{ display: "flex", alignItems: "center" }}
          >
            <span>
              <img src={Ilink} style={{ width: 18, marginRight: 3 }} />
            </span>
            <span>
              Link chụp ảnh mã đơn
              <b style={{ marginLeft: 4 }}>{getParam(data, "orderCode")}</b>
            </span>
          </div>
          <CopyToClipboard
            onCopy={() => message.success("Sao chép link thành công")}
            text={data}
          >
            <img src={Copy} style={{ width: 20 }} />
          </CopyToClipboard>
        </div>
      ))}
      <div style={{ textAlign: "center", marginBottom: 15 }}>
        <Button
          onClick={() => {
            dispatch("closeModalLink");
          }}
          className={classes.button}
        >
          <b>Đóng</b>
        </Button>
      </div>
    </Dialog>
  );
}

const styles = makeStyles(() => ({
  container: { padding: "16px 16px 0px 16px", fontSize: 13 },
  title: { fontSize: 22, marginTop: 10, color: "#383D3B", fontWeight: 600 },
  content: {},
  button: {
    height: 43,
    width: 160,
    color: "#FFF",
    background: "#009b4b",
    borderRadius: 24,
    border: "1px solid #009b4b",
    fontSize: 16,
  },
  btnCopy: {
    color: "#0044CC",
    fontWeight: 400,
    fontSize: 10,
    border: "1px solid #25806D",
    borderRadius: 4,
    padding: "5px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  link: {
    // width: "100%",
    // wordWrap: "break-word",
    color: "#0044CC",
    // textDecoration: "underline",
    cursor: "pointer",
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 5px",
    borderBottom: "1px solid #DCDCDC",
    background: "#F1F1F1",
    margin: "16px 16px",
    borderRadius: 4,
  },
}));
