import React, { useState, useRef, useCallback, useEffect } from "react";
import { Dialog, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Form, Input, Button } from "antd";
import IClose from "../assets/icon/close.svg";
import Ipass from "../assets/images/iconPass.png";
import { getParamUrlByKey } from "../../../extra/utils";
import ImgConfirmPass from "./../assets/images/confirmPass.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmPassword({ open, dispatch, state, isApp}) {
  const { openConfirmPass, errConfirmPass} = state;
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const classes = styles();
  const platform = getParamUrlByKey("platform");

  useEffect(() => {
    if(openConfirmPass){
      form.resetFields();
    }
  }, [openConfirmPass]);

  const onFinish = (val) => {
    // Mật khẩu được truyền trên url API
    form
      .validateFields()
      .then(async (values) => {
        dispatch("closeConfirmPass");
        dispatch({ type: "setPassWord", password: values.password });
        dispatch("payment");
      })
      .catch((err) => {
        // console.error("err:", err);
      });
  };

  return (
    <Dialog
      open={openConfirmPass}
      // open={true}
      TransitionComponent={Transition}
      className={!isApp?'dialogCenter' :"dialogBottom"}
    >
      <Form
        ref={formRef}
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        style={{ height: "100%" }}
      >
        <div className={classes.container}>
          <div style={{ color: "#3A3A3A" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <div style={{ textAlign: "center", width: "100%" }}>
                <img
                  src={ImgConfirmPass}
                  // style={{ width: 15 }}
                />
              </div>
              <img
                onClick={() => {
                  dispatch("closeConfirmPass");
                }}
                src={IClose}
                style={{ width: 15, cursor:'pointer'}}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: 10,
              textAlign: "center",
              fontSize: 20,
              color: "#009B4B",
              fontWeight: "bold",
            }}
          >
            Xác nhận mật khẩu
          </div>
          <div
            style={{
              marginTop: 20,
              textAlign: "center",
            }}
          >
            Vui lòng nhập lại mật khẩu đã đăng ký tài
            <br /> khoản GSale để xác nhận thanh toán
          </div>
          <div style={{ margin: "10px 0px" }}>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập đầy đủ thông tin.",
                },
                {
                  min: 8,
                  message: "Mật khẩu tối thiểu phải có 8 ký tự",
                },
              ]}
              style={{margin: 0}}
            >
              <Input.Password
                prefix={<img src={Ipass} style={{ width: 19 }} />}
                type="text"
                style={{
                  fontSize: 17,
                  padding: "10px 7px 10px 7px",
                  borderRadius: 9,
                  width: "100%",
                  fontWeight: 600,
                }}
                placeholder="Mật khẩu"
              />
            </Form.Item>
            <div style={{color:'red'}}><i>{errConfirmPass}</i></div>
          </div>
          <div style={{ textAlign: "center", width: "100%", margin: '20px 0px'}}>
            <Button
              htmlType="submit"
              size="large"
              style={{
                fontSize: 14,
                background: "#009B4B",
                color: "#fff",
                width: 250,
                textAlign: "center",
                borderRadius: 12,
                fontWeight: 700,
                height: 45,
                border: "1px solid #009B4B",
              }}
            >
              Xác nhận
            </Button>
          </div>
        </div>
      </Form>
    </Dialog>
  );
}

const styles = makeStyles(() => ({
  container: { padding: "16px 16px 0px 16px", fontSize: 14, color: "black" },
}));
